import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import dompurify from 'dompurify'
import { FormattedMessage, useIntl } from 'react-intl'
import SeoNewsSlug from '../seo/SeoNewsSlug'

const changeLinks = (htmlText: string): string => {
  if (!htmlText) return ''

  const splitHtml = htmlText.split('<a')
  let newHtml = splitHtml[0]

  for (let i = 1; i < splitHtml.length; i++) {
    newHtml += `<a target='_blank' rel='noopener noreferrer'${splitHtml[i]}`
  }
  return newHtml
}

export default function WhatsNewBlog({ blog, backUrl, buttonId }: { blog: any; backUrl: string; buttonId: string }) {
  const intl = useIntl()
  const sanitizer = (dirty: any) => dompurify.sanitize(dirty, { ADD_TAGS: ['iframe'] })
  return (
    <>
      <SeoNewsSlug
        title={intl.formatMessage({ id: 'seo.news.title' })}
        description={intl.formatMessage({ id: 'seo.news.description' })}
        entryTitle={blog.title}
        slug={blog.slug}
      />
      <Box sx={{ px: { xs: 2, sm: 4 }, py: { xs: 4, sm: 6 } }} className='news-details-wrapper'>
        <Typography mb={2}>
          {new Date(blog.published_at).toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric'
          })}
        </Typography>
        <Typography component='h1' variant='h1' mb={4}>
          {blog.title}
        </Typography>
        <img src={blog.feature_image} alt={blog.title} />
        <div
          key={blog.id}
          className='news-details'
          dangerouslySetInnerHTML={{ __html: sanitizer(changeLinks(blog.html)) }}
        />
        <Box pt={2} mt={6} sx={{ borderTop: '1px solid #444' }}>
          <Button size='large' color='secondary' component={Link} to={backUrl} startIcon={<ArrowBackIosIcon />}>
            <FormattedMessage id={buttonId} />
          </Button>
        </Box>
      </Box>
    </>
  )
}
