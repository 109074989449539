import React from 'react'
import { Box, Stack, Fade, Typography } from '@mui/material'
import './news.css'
import LazyLoad from 'react-lazyload'
import { useNavigate } from 'react-router-dom'
import { INews } from '../../interface'

const NewsBox = (props: INews) => {
  const navigate = useNavigate()

  const setBlog = (slug: string) => {
    navigate(`${window.location.pathname}/${slug}`)
  }

  return (
    <LazyLoad height={200} offset={1200}>
      <Fade in={true} timeout={1000}>
        <Stack
          className='news-stack'
          key={props.id}
          onClick={() => setBlog(props.slug)}
          direction={{ xs: 'column', md: 'row' }}
        >
          <Box
            sx={{
              display: 'flex',
              flex: { xs: '1 0 100%', md: '1 0 340px' },
              overflow: 'hidden',
              alignItems: 'center'
            }}
          >
            <Box sx={{ overflow: 'hidden', height: '100%' }}>
              <Box
                component='img'
                sx={{
                  height: '100%',
                  display: 'flex',
                  width: '100%',
                  objectFit: 'cover'
                }}
                src={props.feature_image}
                title={props.title}
              />
            </Box>
          </Box>
          <Stack className='news-content' sx={{ py: { xs: 3, sm: 4 }, px: { xs: 2, sm: 4 } }}>
            <Typography variant='body2' mb={2}>
              {new Date(props.published_at).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
              })}
            </Typography>
            <Typography component='h2' variant='h4' mb={1}>
              {props.title}
            </Typography>
            <Typography className='news-excerpt'>{props.custom_excerpt}</Typography>
          </Stack>
        </Stack>
      </Fade>
    </LazyLoad>
  )
}
export default NewsBox
