import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

const SeoCsReleaseNotes = ({ title, description }) => {
  const { pathname } = useLocation()

  return (
    <Helmet>
      <title>{title + ' | Gemvision'}</title>
      <meta name='description' content={description} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <link rel='canonical' href={`https://gemvision.com${pathname}`} />
      <script type='application/ld+json'>
        {`{
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [
              {
                "@type": "ListItem", 
                "position": 1, 
                "name": "CounterSketch",
                "item": "https://gemvision.com/countersketch"
              },
              {
                "@type": "ListItem", 
                "position": 2, 
                "name": "CounterSketch Release Notes",
                "description": "Explore the latest CounterSketch updates to enhance your jewelry design experience.",
                "item": "https://gemvision.com/countersketch/release-notes"
              }
            ]
        }`}
      </script>
    </Helmet>
  )
}

export default SeoCsReleaseNotes
