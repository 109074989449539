import {
  GET_RELEASE_CANDIDATE_NOTES,
  GET_RELEASE_NOTES_MG,
  GET_NEWS_DATA,
  GET_WHATS_NEW_CS,
  GET_WHATS_NEW_MG,
  GET_RELEASE_NOTES_CS
} from '../actions'
import { IReleaseNotes, INews } from '../interface'

const initialState = {
  news: [],
  releaseNotesMG: [],
  releaseNotesCS: [],
  releaseCandidateNotes: [],
  whatsNewCS: null,
  whatsNewMG: null
}

export const updatesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_RELEASE_CANDIDATE_NOTES:
      const releaseCandidateNotes = [
        ...action.payload.map(
          ({ id, slug, title, feature_image, published_at, custom_excerpt, html }: IReleaseNotes) => ({
            id,
            slug,
            title,
            feature_image,
            published_at,
            custom_excerpt,
            html
          })
        )
      ]
      return { ...state, releaseCandidateNotes }
    case GET_RELEASE_NOTES_MG:
      const releaseNotesMG = [
        ...action.payload.map(
          ({ id, slug, title, feature_image, published_at, custom_excerpt, html }: IReleaseNotes) => ({
            id,
            slug,
            title,
            feature_image,
            published_at,
            custom_excerpt,
            html
          })
        )
      ]
      return { ...state, releaseNotesMG }
    case GET_NEWS_DATA:
      const news = [
        ...action.payload.map(({ id, slug, title, feature_image, published_at, custom_excerpt, html }: INews) => ({
          id,
          slug,
          title,
          feature_image,
          published_at,
          custom_excerpt,
          html
        }))
      ]
      return { ...state, news }
    case GET_RELEASE_NOTES_CS:
      const releaseNotesCS = [
        ...action.payload.map(({ id, slug, title, feature_image, published_at, custom_excerpt, html }: INews) => ({
          id,
          slug,
          title,
          feature_image,
          published_at,
          custom_excerpt,
          html
        }))
      ]
      return { ...state, releaseNotesCS }
    case GET_WHATS_NEW_CS:
      const whatsNewCS = [
        ...action.payload.map(({ id, slug, title, feature_image, published_at, custom_excerpt, html }: INews) => ({
          id,
          slug,
          title,
          feature_image,
          published_at,
          custom_excerpt,
          html
        }))
      ]
      return { ...state, whatsNewCS }
    case GET_WHATS_NEW_MG:
      const whatsNewMG = [
        ...action.payload.map(({ id, slug, title, feature_image, published_at, custom_excerpt, html }: INews) => ({
          id,
          slug,
          title,
          feature_image,
          published_at,
          custom_excerpt,
          html
        }))
      ]
      return { ...state, whatsNewMG }
    default:
      return state
  }
}
