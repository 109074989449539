import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

const SeoMgReleaseNotes = ({ title, description }) => {
  const { pathname } = useLocation()

  return (
    <Helmet>
      <title>{title + ' | Gemvision'}</title>
      <meta name='description' content={description} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <link rel='canonical' href={`https://gemvision.com${pathname}`} />
      <script type='application/ld+json'>
        {`{
          "@context": "https://schema.org/", 
          "@type": "BreadcrumbList", 
          "itemListElement": [{
            "@type": "ListItem", 
            "position": 1, 
            "name": "MatrixGold",
            "item": "https://gemvision.com/matrixgold"
          }, {
            "@type": "ListItem", 
            "position": 2, 
            "name": "MatrixGold Release Notes",
            "description": "Stay ahead with the latest software updates, featuring new jewelry design tools, features, and enhancements. Explore them now to elevate your design workflow.",
            "item": "https://gemvision.com/matrixgold/release-notes"
          }]
        }`}
      </script>
    </Helmet>
  )
}

export default SeoMgReleaseNotes
