import React from 'react'
import { Helmet } from 'react-helmet'

const SeoNewsSlug = ({ title, description, entryTitle, slug }) => {
  return (
    <Helmet>
      <title>{entryTitle + ' | Gemvision'}</title>
      <meta name='description' content={description} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <script type='application/ld+json'>
        {`{
          "@context": "https://schema.org/", 
          "@type": "BreadcrumbList", 
          "itemListElement": [{
            "@type": "ListItem", 
            "position": 1, 
            "name": "News",
            "description": "Explore the latest updates in leading jewelry design software, including MatrixGold and CounterSketch.",
            "item": "https://gemvision.com/news"  
          },
          {
            "@type": "ListItem", 
            "position": 2, 
            "name": "${entryTitle}",
            "description": "Explore the latest updates in leading jewelry design software, including MatrixGold and CounterSketch.",
            "item": "https://gemvision.com/news/${slug}"  
          },
          ]
        }`}
      </script>
    </Helmet>
  )
}

export default SeoNewsSlug
