import React from 'react'
import Skeleton from '@mui/material/Skeleton'

export default function NewsSkeleton() {
  return (
    <>
      {[...Array(5)].map((_, index) => (
        <Skeleton key={index} sx={{ height: 191, mb: 3 }} />
      ))}
    </>
  )
}
