import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getReleaseNotesMG, getReleaseNotesCS, getReleaseCandidateNotes } from '../../actions'
import { Navigate, useLocation } from 'react-router-dom'
import { Typography, Box } from '@mui/material'
import { IReducer, IReleaseNotes } from '../../interface'
import { FormattedMessage, useIntl } from 'react-intl'
import WhatsNewBlog from '../../components/Common/WhatsNewBlog'
import NewsBox from '../../containers/News/NewsBox'
import SeoCsReleaseNotes from '../seo/SeoCsReleaseNotes'
import SeoMgReleaseNotes from '../seo/SeoMgReleaseNotes'
import SimpleSpinner from '../Common/SimpleSpinner'
import NewsSkeleton from '../../containers/News/NewsSkeleton'

function getData(software: any, state: any) {
  if (software === 'matrixgold') {
    return state.updatesReducer.releaseNotesMG
  } else if (software === 'countersketch') {
    return state.updatesReducer.releaseNotesCS
  } else {
    return state.updatesReducer.releaseCandidateNotes
  }
}

function getParentPath(software: string) {
  if (software === 'matrixgold' || software === 'countersketch') {
    return `/${software}/release-notes`
  }
  return '/release-candidate-notes'
}

const ReleaseNotes = ({ activeSlug, software }: { activeSlug?: boolean; software: string }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const intl = useIntl()
  const releasenotes = useSelector((state: IReducer) => getData(software, state))
  const [releaseSlug, setReleaseSlug] = useState<null | string>(null)
  const [showAll, setShowAll] = useState(true)
  const [loading, setLoading] = useState(false)
  const parentPath = getParentPath(software)

  useEffect(() => {
    if (activeSlug) {
      const slug = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)
      setReleaseSlug(slug)
      setShowAll(false)
    } else {
      setShowAll(true)
      setReleaseSlug(null)
    }
    getNotes()
    setLoading(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSlug])

  useEffect(() => {
    if (releasenotes.length !== 0) {
      setLoading(false)
    }
  }, [releasenotes])

  const getNotes = () => {
    if (software === 'matrixgold') {
      dispatch(getReleaseNotesMG())
    } else if (software === 'countersketch') {
      dispatch(getReleaseNotesCS())
    } else {
      dispatch(getReleaseCandidateNotes())
    }
  }

  const renderDetails = () => {
    const blog = releasenotes.find((blog: IReleaseNotes) => releaseSlug === blog.slug)
    if (releasenotes.length === 0) {
      return <SimpleSpinner />
    } else if (blog === undefined) {
      return <Navigate to={parentPath} replace />
    }
    return <WhatsNewBlog blog={blog} backUrl={parentPath} buttonId='common.releaseNotes' />
  }

  const renderReleaseNotes = () => {
    return (
      <Box
        sx={{ maxWidth: { xs: 480, md: 1200 }, margin: 'auto', py: 6, px: { xs: 2, md: 4 } }}
        data-cy='releaseNotes-container'
      >
        <Typography component='h1' variant='h2' mb={6}>
          <FormattedMessage id='common.releaseNotes' />
        </Typography>
        {loading && <NewsSkeleton />}
        {releasenotes.map((blog: IReleaseNotes) => (
          <NewsBox
            key={blog.id}
            id={blog.id}
            title={blog.title}
            slug={blog.slug}
            feature_image={blog.feature_image}
            custom_excerpt={blog.custom_excerpt}
            published_at={blog.published_at}
            html={blog.html}
          />
        ))}
      </Box>
    )
  }

  return (
    <Box>
      {software === 'matrixgold' ? (
        <SeoMgReleaseNotes
          title={intl.formatMessage({ id: 'seo.matrixgold.releaseNotes.title' })}
          description={intl.formatMessage({ id: 'seo.matrixgold.releaseNotes.description' })}
        />
      ) : (
        <SeoCsReleaseNotes
          title={intl.formatMessage({ id: 'seo.countersketch.releaseNotes.title' })}
          description={intl.formatMessage({ id: 'seo.countersketch.releaseNotes.description' })}
        />
      )}
      {showAll ? renderReleaseNotes() : renderDetails()}
    </Box>
  )
}

export default ReleaseNotes
