import GhostContentAPI from '@tryghost/content-api'
import { Dispatch } from 'redux'
import { NotificationManager } from '../functions/notifications'
import { FormattedMessage } from 'react-intl'

export const GET_RELEASE_CANDIDATE_NOTES = 'GET_RELEASE_CANDIDATE_NOTES'
export const GET_RELEASE_NOTES_MG = 'GET_RELEASE_NOTES_MG'
export const GET_NEWS_DATA = 'GET_NEWS_DATA'
export const GET_WHATS_NEW_CS = 'GET_WHATS_NEW_CS'
export const GET_WHATS_NEW_MG = 'GET_WHATS_NEW_MG'
export const GET_RELEASE_NOTES_CS = 'GET_RELEASE_NOTES_CS'

export const api = new GhostContentAPI({
  url: 'https://blog.gemvision.cloud',
  key: 'c3cf0f0c6f8d7dd3b525c072ad',
  version: 'v3.0'
})

const request = async (dispatch: Dispatch, actionType: string, config: { limit: string; filter: string }) => {
  try {
    const response = await api.posts.browse(config)
    dispatch({
      type: actionType,
      payload: response
    })
  } catch (error: any) {
    NotificationManager.warning(<FormattedMessage id='notification.UnexpectedError' />)
    console.error(`Error on ${actionType}: ${JSON.stringify(error.message)}`)
    dispatch({
      type: actionType,
      payload: []
    })
  }
}

export const getReleaseCandidateNotes = () => {
  return (dispatch: Dispatch) => {
    request(dispatch, GET_RELEASE_CANDIDATE_NOTES, { limit: 'all', filter: 'tags:Release-Candidate-Notes' })
  }
}

export const getReleaseNotesMG = () => {
  return (dispatch: Dispatch) => {
    request(dispatch, GET_RELEASE_NOTES_MG, { limit: 'all', filter: 'tags:MatrixGold+tags:Release-Notes' })
  }
}

export const getReleaseNotesCS = () => {
  return (dispatch: Dispatch) => {
    request(dispatch, GET_RELEASE_NOTES_CS, { limit: 'all', filter: 'tags:CounterSketch+tags:Release-Notes' })
  }
}

export const getNews = () => {
  return async (dispatch: Dispatch) => {
    request(dispatch, GET_NEWS_DATA, { limit: 'all', filter: 'tags:News' })
  }
}

export const getWhatsNewCS = () => {
  return async (dispatch: Dispatch) => {
    request(dispatch, GET_WHATS_NEW_CS, { limit: 'all', filter: 'tags:CounterSketch+tags:News' })
  }
}

export const getWhatsNewMG = () => {
  return async (dispatch: Dispatch) => {
    request(dispatch, GET_WHATS_NEW_MG, { limit: 'all', filter: 'tags:MatrixGold+tags:News' })
  }
}
